import React from 'react';
import PropTypes from 'prop-types';
import {
  Center,
  FormControl, FormLabel, Icon, Text, VisuallyHiddenInput, VStack,
} from '@chakra-ui/react';
import { FaFileUpload } from 'react-icons/fa';

function handleDrag(e) {
  e.preventDefault();
  e.stopPropagation();
}

class DragAndDrop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dragging: false,
    };
    this.dropRef = React.createRef();
    this.handleDragIn = this.handleDragIn.bind(this);
    this.handleDragOut = this.handleDragOut.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
  }

  componentDidMount() {
    const dropzone = this.dropRef.current;
    dropzone.addEventListener('dragenter', this.handleDragIn);
    dropzone.addEventListener('dragleave', this.handleDragOut);
    dropzone.addEventListener('dragover', handleDrag);
    dropzone.addEventListener('drop', this.handleDrop);
  }

  componentWillUnmount() {
    const dropzone = this.dropRef.current;
    dropzone.removeEventListener('dragenter', this.handleDragIn);
    dropzone.removeEventListener('dragleave', this.handleDragOut);
    dropzone.removeEventListener('dragover', handleDrag);
    dropzone.removeEventListener('drop', this.handleDrop);
  }

  handleDragIn(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ dragging: true });
    }
  }

  handleDragOut(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragging: false });
  }

  handleDrop(e) {
    const { handleDrop } = this.props;
    e.preventDefault();
    e.stopPropagation();
    handleDrop(e.dataTransfer.files);
    this.setState({ dragging: false });
  }

  render() {
    const { handleDrop, icon } = this.props;
    const { dragging } = this.state;

    return (
      <VStack align="center" borderWidth={2} borderStyle="dashed" borderColor="primary" p={5} ref={this.dropRef} spacing={0}>
        {dragging
          ? (
            <>
              <Icon as={FaFileUpload} boxSize={16} />
              <Text>Solte para fazer o upload</Text>
            </>
          )
          : (
            <>
              <Icon as={icon} boxSize={16} />
              <Center>
                <FormControl>
                  <FormLabel>Escolher arquivo</FormLabel>
                  <VisuallyHiddenInput type="file" name="file" onChange={(e) => { handleDrop(e.target.files); }} />
                </FormControl>
              </Center>
              <Text>ou arraste para esta janela</Text>
            </>
          )}
      </VStack>
    );
  }
}

DragAndDrop.propTypes = {
  handleDrop: PropTypes.func.isRequired,
  icon: PropTypes.func.isRequired,
};

export default DragAndDrop;
