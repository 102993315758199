import Papa from 'papaparse';

const alunoparser = (file) => new Promise((resolve, reject) => {
  let alunosComErro = 0;
  Papa.parse(file, {
    skipEmptyLines: true,
    header: true,
    dynamicTyping: true,
    encoding: 'UTF-8',
    beforeFirstChunk: (chunk) => {
      const rows = chunk.split(/\r\n|\r|\n/);
      const headings = rows[0].toLowerCase();
      rows[0] = headings;
      return rows.join('\r\n');
    },
    complete: (results) => {
      const alunos = results.data;
      alunos.forEach((aluno) => {
        let erro = false;
        if ((!aluno.nome)
          || (!aluno.data_de_nascimento)
          || (!aluno.sexo)
          || (!aluno.serie)
          || (!aluno.local_sede)
          || (aluno.local < 1 || aluno.local > 4)
          || (aluno.serie < 1 || aluno.serie > 9)
          || (aluno.necessidade !== 0 && aluno.necessidade !== 1)
          || (aluno.sexo !== 'M' && aluno.sexo !== 'F')) {
          // console.log(aluno);
          erro = true;
        }
        if (aluno.renda_familiar && (aluno.renda_familiar < 1 || aluno.renda_familiar > 6)) {
          erro = true;
        }
        if (aluno.necessidade === 1 && !aluno.necessidade_especificada) {
          erro = true;
        }
        if (aluno.data_de_nascimento.split('-').length !== 3) {
          erro = true;
        }
        switch (aluno.serie) {
          case 6:
          case 7:
            aluno.nivel = 1; // eslint-disable-line no-param-reassign
            break;
          case 8:
          case 9:
            aluno.nivel = 2; // eslint-disable-line no-param-reassign
            break;
          case 1:
          case 2:
          case 3:
            aluno.nivel = 3; // eslint-disable-line no-param-reassign
            break;
          default:
            aluno.nivel = 0; // eslint-disable-line no-param-reassign
        }
        if (erro) {
          alunosComErro += 1;
        }
      });
      if (alunosComErro > 0) { reject(alunosComErro); }
      resolve(alunos);
    },
  });
});

export default alunoparser;
