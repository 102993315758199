import React from 'react';
import PropTypes from 'prop-types';
import {
  Alert, Container, Heading, ListItem, OrderedList, Table, Tbody, Td, Text, Th, Tr,
} from '@chakra-ui/react';
// import DragAndDrop from '../DragAndDrop/index';
// import { faReceipt } from '@fortawesome/free-solid-svg-icons';

import api from '../../providers/api';

class Pagamento extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      statusMsg: '',
    };
    this.uploadFile = this.uploadFile.bind(this);
  }

  componentDidUpdate() {
    const { statusMsg } = this.state;
    if (statusMsg) {
      setTimeout(() => {
        this.setState({ statusMsg: '' });
      }, 5000);
    }
  }

  uploadFile(files) {
    const { user } = this.props;
    if (files[0] !== null && files[0].type === 'application/pdf') {
      const data = new FormData();
      data.append('escola', user.escola.toUpperCase());
      data.append('file', files[0]);

      api.post('/comprovante', data)
        .Then(() => this.setState({
          error: false,
          statusMsg: 'Comprovante enviado',
        }))
        .catch((error) => this.setState({
          error: true,
          statusMsg: `Houve um erro no envio: ${error.message}`,
        }));
    } else {
      this.setState({
        error: true,
        statusMsg: 'Arquivo inválido',
      });
    }
  }

  render() {
    const { error, statusMsg } = this.state;
    return (
      <>
        {statusMsg !== ''
          && (
          <Alert status={error ? 'error' : 'success'}>
            {statusMsg}
          </Alert>
          )}
        <Container>
          <Heading as="h1" mb={3}>Instruções</Heading>
          <OrderedList>
            <ListItem>
              O valor da inscrição corresponde a:
              <Text>(R$20,00) x (número de alunos da escola inscritos pelo tutor)</Text>
              <Text fontSize="xs">Por exemplo: R$ 400,00 para 20 alunos inscritos.</Text>
            </ListItem>
            <ListItem>
              Fazer depósito na conta a seguir:
              <Table my={3}>
                <Tbody>
                  <Tr>
                    <Th>Titular</Th>
                    <Td>
                      Fundação de Apoio a Serviços Técnicos, Ensino e Fomento a
                      Pesquisas - Fundação ASTEF
                    </Td>
                  </Tr>
                  <Tr>
                    <Th>Banco</Th>
                    <Td>Banco Do Brasil</Td>
                  </Tr>
                  <Tr>
                    <Th>Agência</Th>
                    <Td>2937-8</Td>
                  </Tr>
                  <Tr>
                    <Th>Conta</Th>
                    <Td>38968-X</Td>
                  </Tr>
                  <Tr>
                    <Th>CNPJ</Th>
                    <Td>08.918.421/0001-08.</Td>
                  </Tr>
                </Tbody>
              </Table>
            </ListItem>
            <ListItem>
              Enviar, em formato PDF, o comprovante de depósito pelo formulário abaixo.
            </ListItem>
          </OrderedList>
        </Container>
        {/* <DragAndDrop icon={faReceipt} handleDrop={this.uploadFile}/> */}
        <Heading as="h1">NOTA</Heading>
        <Heading as="h3" size="md">O envio de comprovante pelo site não está disponível no momento.</Heading>
        <Heading as="h3" size="md">
          Pedimos que envie o comprovante em anexo por email para
          {' '}
          <b>organizacaooci@gmail.com</b>
        </Heading>
        <Heading as="h3" size="md">Agradecemos a compreensão!</Heading>
      </>
    );
  }
}

Pagamento.propTypes = {
  user: PropTypes.shape({
    escola: PropTypes.string,
  }).isRequired,
};

export default Pagamento;
