import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import jwtDecode from 'jwt-decode';
import {
  Tabs, Tab, TabList, TabPanels, TabPanel, Text, Flex, Button,
} from '@chakra-ui/react';
import { getToken, logout } from '../providers/auth';

import Layout from '../components/layout';
import Inscrever from '../components/admin/Inscrever';
import Inscritos from '../components/admin/Inscritos';
import Pagamento from '../components/admin/Pagamento';
import Tutores from '../components/admin/Tutores';

import data from '../data.json';

function logoutAndGoBack() {
  logout();
  navigate('/login');
}

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      inscAtivas: false,
    };
  }

  componentDidMount() {
    const token = getToken();
    const user = jwtDecode(token);
    // formato: mm/dd/yyyy
    const prazo = data.inscricoes.split(' - ').map((dateStr) => new Date(dateStr));
    const currDate = new Date();
    this.setState({ user, inscAtivas: (currDate >= prazo[0] && currDate <= prazo[1]) });
  }

  render() {
    const { user, inscAtivas } = this.state;
    const { location } = this.props;
    if (user !== null) {
      return (
        <Layout location={location.pathname}>
          <Flex align="center" bg="primary" color="white" justify="space-between" py={2} px={5}>
            <Text>{`Olá, ${user.nome}`}</Text>
            <Button color="white" onClick={() => { logoutAndGoBack(); }} variant="link">SAIR</Button>
          </Flex>
          <Tabs colorScheme="teal" orientation="vertical" variant="solid-rounded">
            <TabList mx={3} mt={5}>
              <Tab>Inscrever Competidor</Tab>
              <Tab>Listar Inscritos</Tab>
              {user.natureza === 1 && <Tab>Pagamento</Tab>}
              {user.nivel >= 5 && <Tab>Tutores</Tab>}
            </TabList>
            <TabPanels>
              <TabPanel><Inscrever user={user} inscAtivas={inscAtivas} /></TabPanel>
              <TabPanel><Inscritos user={user} /></TabPanel>
              {user.natureza === 1 && <TabPanel><Pagamento user={user} /></TabPanel>}
              {user.nivel >= 5 && <TabPanel><Tutores user={user} /></TabPanel>}
            </TabPanels>
          </Tabs>
        </Layout>
      );
    }
    return <h1>Carregando</h1>;
  }
}

Admin.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default Admin;
