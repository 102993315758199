import React from 'react';
import PropTypes from 'prop-types';
import {
  Center, Heading, Spinner, Table, TableCaption, Tbody, Text, Th, Thead, Tr,
} from '@chakra-ui/react';
import api from '../../providers/api';

class Inscritos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      inscritos: [],
    };
  }

  componentDidMount() {
    const { user } = this.props;
    const path = user.nivel >= 5 ? 'aluno' : `/aluno/${user.escola}`;
    api.get(path)
      .then((res) => this.setState({
        inscritos: res.data,
        loading: false,
      }));
  }

  render() {
    const { loading, inscritos } = this.state;

    return (
      <>
        {loading && <Center><Spinner /></Center>}
        {!loading && inscritos.length === 0 && <Text>Não há nenhum aluno inscrito.</Text>}
        {!loading && inscritos.length !== 0
      && (
      <Heading as="h3">
        Listando
        {' '}
        <b>{inscritos.length}</b>
        {' '}
        aluno(s)
      </Heading>
      )}
        <Table variant="simple">
          <TableCaption>Lista de Alunos Inscritos</TableCaption>
          <Thead>
            <Tr>
              <Th>Nome</Th>
              <Th>Sexo</Th>
              <Th>Série</Th>
            </Tr>
          </Thead>
          <Tbody>
            {inscritos.map((aluno) => (
              <Tr key={aluno.nome}>
                <Th>{aluno.nome}</Th>
                <Th>{aluno.sexo}</Th>
                <Th>{`${aluno.serie}º ano`}</Th>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </>
    );
  }
}

Inscritos.propTypes = {
  user: PropTypes.shape({
    nivel: PropTypes.number.isRequired,
    escola: PropTypes.string.isRequired,
  }).isRequired,
};

export default Inscritos;
