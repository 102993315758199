import React from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Center,
  Flex,
  Heading,
  Icon, Link, Spinner, Text,
} from '@chakra-ui/react';
import { FaFileCsv, FaQuestionCircle, FaFileDownload } from 'react-icons/fa';
import qs from 'querystring';
import alunoparser from '../../util/alunoparser';
import DragAndDrop from '../DragAndDrop';

import api from '../../providers/api';

class Inscrever extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      statusMsg: '',
    };
    this.uploadFile = this.uploadFile.bind(this);
  }

  componentDidUpdate() {
    const { statusMsg } = this.state;
    if (statusMsg) {
      setTimeout(() => {
        this.setState({ statusMsg: '' });
      }, 5000);
    }
  }

  async uploadFile(files) {
    function checkFileType(filename) {
      const ext = filename.substring(filename.lastIndexOf('.') + 1).toLowerCase();
      return ext === 'csv' || ext === 'txt';
    }

    if (files[0] && checkFileType(files[0].name)) {
      this.setState({ loading: true });
      const alunosArr = [];
      let alunosInscritos = [];
      const { user } = this.props;
      await api.get(`aluno/${user.escola}`)
        .then((result) => { alunosInscritos = [...result.data]; });
      alunoparser(files[0])
        .then((results) => {
          results.forEach((aluno) => {
            const jaInscrito = alunosInscritos.some((inscrito) => {
              if ((inscrito.nome === aluno.nome)
              && (inscrito.data_de_nascimento === aluno.data_de_nascimento)) {
                return true;
              }
              return false;
            });
            if (!jaInscrito) {
              alunosArr.push([
                aluno.nome,
                aluno.data_de_nascimento,
                aluno.sexo,
                aluno.rg,
                aluno.cpf,
                aluno.serie,
                aluno.nivel,
                aluno.cidade,
                aluno.bairro,
                aluno.renda_familiar,
                aluno.local_sede,
                aluno.necessidade,
                aluno.necessidade_especificada,
                user.escola,
              ]);
            }
          });
          if (alunosArr.length > 0) {
            api.post('/aluno', {
              params: alunosArr,
              paramsSerializer: (params) => qs.stringify(params),
            })
              .then(() => this.setState({ error: false, statusMsg: 'Alunos cadastrados', loading: false }))
              .catch((err) => this.setState({ error: true, statusMsg: `Ocorreu um erro no cadastro: ${err.message}`, loading: false }));
          }
        })
        .catch((err) => this.setState({ error: true, statusMsg: `Houve erro no cadastro de ${err} aluno(s)`, loading: false }));
    } else {
      this.setState({ error: true, statusMsg: 'Arquivo inválido' });
    }
  }

  render() {
    const { inscAtivas, user } = this.props;
    const { error, loading, statusMsg } = this.state;
    if (inscAtivas || user.nivel >= 5) {
      return (
        <>
          {statusMsg !== ''
          && (
          <Alert status={error ? 'error' : 'success'}>
            {statusMsg}
          </Alert>
          )}
          <Flex
            alignItems="center"
            justifyContent="center"
          >
            <Link href="/documentos/Tutorial.pdf" isExternal m={3}>
              <Center>
                <Icon as={FaQuestionCircle} boxSize={16} mx="auto" />
              </Center>
              <Text textAlign="center">Checar tutorial</Text>
            </Link>
            <Link href="/documentos/Exemplo.csv" isExternal m={3}>
              <Center>
                <Icon as={FaFileDownload} boxSize={16} />
              </Center>
              <Text textAlign="center">Baixar modelo</Text>
            </Link>
          </Flex>
          {loading
            ? <Center><Spinner /></Center>
            : <DragAndDrop color="primary" icon={FaFileCsv} handleDrop={this.uploadFile} />}
        </>
      );
    }
    return (
      <Center>
        <Heading as="h2">Acabou o período de inscrições. Contamos com você próximo ano!</Heading>
      </Center>
    );
  }
}

Inscrever.propTypes = {
  inscAtivas: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    nivel: PropTypes.number.isRequired,
    escola: PropTypes.string.isRequired,
  }).isRequired,
};

export default Inscrever;
