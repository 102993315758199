import React from 'react';
import {
  Alert, Button, Checkbox, Spinner, Table, Tbody, Td, Thead, Tr,
} from '@chakra-ui/react';
import api from '../../providers/api';

class Tutores extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usuarios: [],
      updatedUsuarios: [],
      loading: true,
      error: false,
    };
    this.changeUserStatus = this.changeUserStatus.bind(this);
    this.updateValues = this.updateValues.bind(this);
  }

  componentDidMount() {
    api.get('/users')
      .then((results) => this.setState({
        usuarios: results.data.filter((e) => e.nivel < 5),
        loading: false,
      }));
  }

  componentDidUpdate() {
    const { statusMsg } = this.state;
    if (statusMsg) {
      setTimeout(() => {
        this.setState({ statusMsg: '' });
      }, 5000);
    }
  }

  changeUserStatus(id, status) {
    const { updatedUsuarios } = this.state;
    const newUpdated = [...updatedUsuarios];
    const index = newUpdated.findIndex((x) => x.id === id);
    if (index >= 0) {
      newUpdated[index].ativado = status ? 1 : 0;
      newUpdated[index].nivel = status ? 2 : 0;
    } else {
      newUpdated.push({ id, ativado: status ? 1 : 0, nivel: status ? 2 : 0 });
    }
    this.setState({ updatedUsuarios: [...newUpdated] });
  }

  updateValues() {
    const { updatedUsuarios } = this.state;
    updatedUsuarios.forEach((usuario) => api.patch(`/users/${usuario.id}`, { ...usuario })
      .then(() => this.setState({ error: false, statusMsg: 'Dados atualizados' }))
      .catch((err) => this.setState({ error: true, statusMsg: `Erro: ${err.message}` })));
  }

  render() {
    const {
      error, loading, statusMsg, usuarios,
    } = this.state;
    return (
      <>
        {error
          && (
          <Alert status={error ? 'error' : 'success'}>
            {statusMsg}
          </Alert>
          )}
        <Table>
          <Thead>
            <Tr>
              <Td>Cadastrado</Td>
              <Td>Escola</Td>
              <Td>Nome do Tutor</Td>
              <Td>e-mail</Td>
            </Tr>
          </Thead>
          <Tbody>
            {loading && <Spinner />}
            {usuarios.map((usuario) => (
              <Tr className="tutor" key={usuario.nome}>
                <Td>
                  <Checkbox
                    id={usuario.nome}
                    defaultChecked={usuario.ativado}
                    onChange={(e) => this.changeUserStatus(usuario.id, e.target.checked)}
                  />
                </Td>
                <Td><b>{usuario.escola.toUpperCase()}</b></Td>
                <Td>{usuario.nome.toUpperCase()}</Td>
                <Td>{usuario.email}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        {!loading
      && <Button onClick={() => { this.updateValues(); }}>Atualizar dados</Button>}
      </>
    );
  }
}

export default Tutores;
